/* You can add global styles to this file, and also import other style files */

@import "primeflex/primeflex.css";
@import "~@fortawesome/fontawesome-free/css/all.css";


body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  //padding: 0;
  margin: 0;
  height: 100%;
  max-height: 100vh;
  font-size: 15px;
  overflow-x: hidden;
  //padding: 5px;
}

.p-multiselect-label {
  display: block;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces !important;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.1rem !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin: 0.2rem !important;
}

.p-multiselect {
  padding: 0 !important;
}

.mega {
  transform: scale(1.5);
}

.mat-table tr {
  height: 4rem !important;
}

.hide-mobile {

}

@media screen and (max-width: 992px), (orientation: portrait) {

  .mat-table tr {
    height: 6rem !important;
  }

  .hide-mobile {
    display: none
  }

  .p-datatable-header {
    .p-button {
      justify-content: center;
      min-width: 3rem;

      .p-button-icon {
        margin: 0;
      }

    }

    .p-button-label {
      visibility: hidden;
      width: 0;
      height: 1rem;
      flex: 0 0 auto
    }
  }


  span.mat-button-wrapper {
    mat-icon.mat-icon {
      margin-left: 0.5rem
    }
  }


}

@media screen and (max-width: 1200px), (orientation: portrait) {

  .mat-table tr {
    height: 6rem !important;
  }

  .p-datatable-header {
    .p-button {
      justify-content: center;
      min-width: 3rem;

      .p-button-icon {
        margin: 0;
      }
    }

    .p-button-label {
      visibility: hidden;
      width: 0;
      height: 1rem;
      flex: 0 0 auto
    }

    span.mat-button-wrapper {
        margin-right: 5px
    }
  }
}



.slider-content > * {

  display: flex !important;
  flex: 1 1 0 !important;
  height: 100% !important;
}

.p-datatable-xs {
  tr {
    height: 2rem !important;
  }
}



// MATERIAL
@import 'custom-theme.scss';
$primary: map-get($theme-primary, main);
$primary-light: map-get($theme-primary, lighter);
$warn: map-get($theme-warn, main);
$warn-light: map-get($theme-warn, lighter);
$accent: map-get($theme-accent, main);
$dark: map-get($mat-light-theme-foreground, base);


/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

:root {
  --amplify-primary-color: #{$primary};
  --amplify-primary-tint: #{$primary};
  --amplify-primary-shade: #{$primary};
}

.mat-form-field {
  margin-bottom: .75em;
}



//       __   __  _______  ___   ___      _______
//      |  | |  ||       ||   | |   |    |       |
//      |  | |  ||_     _||   | |   |    |  _____|
//      |  | |  |  |   |  |   | |   |    | |_____
//      |  |_|  |  |   |  |   | |   |___ |_____  |
//      |       |  |   |  |   | |       | _____| |
//      |_______|  |___|  |___| |_______||_______|


// @formatter:off
.uppercase {
  text-transform: uppercase;
}

.underline{
  text-decoration: underline;
}

.strikethrough {
  text-decoration: overline;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.text-shadow {
  text-shadow: 0 0 4px black;
}


.mat-background-primary {
  background-color: $primary !important;
}

.mat-background-primary-light {
  background-color: $primary-light !important;
}

.mat-background-accent {
  background-color: $accent !important;
}

.mat-background-warn {
  background-color: $warn !important;
}

.mat-background-dark {
  background-color: $dark !important;
}

.mat-background-light {
  background-color: $light-background !important;
}

.mat-background-none {
  background-color: transparent !important;
}

.border {
  border: solid 1px !important;
}

.border-bottom {
  border-style: solid  !important;
  border-width:  0 0 1px 0  !important;
}

.overflow-hidden{
  overflow: hidden !important;
}
.overflow-x-hidden{
  overflow-x: hidden !important;
}

.overflow-scroll{
  overflow: scroll;
}

.border-primary {
  border-color: $primary !important;
}

.border-accent {
  border-color: $accent !important;
}

.border-light {
  border-color: $light-background !important;
}

.border-warn {
  border-color: $warn !important;
}

.border-yellow-warn {
  border-color: rgba(255,202,54,0.85) !important;
}

.border-blue-info {
  border-color: $accent !important;
}

.color-primary {
  color: $primary !important;
}

.color-accent {
  color: $accent !important;
}

.color-warn {
  color: $warn !important;
}

.color-light {
  color: $light-background !important;
}

.color-disabled {
  color: $dark-disabled-text !important;
}

.color-dark {
  color: $dark !important;
}

.rounded {
  border-radius: 50% !important;
}

.tilt-30 {
  transform: rotate(-30deg);
}

.m-0    {margin: 0 !important;}
.m-2_5  {margin: 2.5% !important;}
.m-5    {margin: 5% !important;}
.m-10   {margin: 10% !important;}
.m-15   {margin: 15% !important;}
.m-20   {margin: 20% !important;}
.m-25   {margin: 25% !important;}
.m-30   {margin: 30% !important;}

.m-2-rem {margin: 0.5rem !important;}

.mt-0   {margin-top: 0 !important;}
.mt-1_5 {margin-top: 1.5% !important;}
.mt-2   {margin-top: 2% !important;}
.mt-2_5 {margin-top: 2.5% !important;}
.mt-5   {margin-top: 5% !important;}
.mt-10  {margin-top: 10% !important;}
.mt-15  {margin-top: 15% !important;}
.mt-20  {margin-top: 20% !important;}
.mt-25  {margin-top: 25% !important;}
.mt-30  {margin-top: 30% !important;}
.mt-35  {margin-top: 35% !important;}
.mt-40  {margin-top: 40% !important;}
.mt-45  {margin-top: 45% !important;}
.mt-50  {margin-top: 50% !important;}
.mt-55  {margin-top: 55% !important;}
.mt-100 {margin-top: 100% !important;}


.mt-2_5-neg {  margin-top: -2.5% !important;}
.mt-5-neg {  margin-top: -5% !important;}
.mt-10-neg {  margin-top: -10% !important;}
.mt-15-neg {  margin-top: -15% !important;}
.mt-20-neg {  margin-top: -20% !important;}
.mt-25-neg {  margin-top: -25% !important;}
.mt-30-neg {  margin-top: -30% !important;}

.mb-0 {  margin-bottom: 0 !important;}
.mb-0_5 {  margin-bottom: 0.5% !important;}
.mb-1 {  margin-bottom: 1% !important;}
.mb-1_5 {  margin-bottom: 1.5% !important;}
.mb-2_5 {  margin-bottom: 2.5% !important;}
.mb-3 {  margin-bottom: 3% !important;}
.mb-5 {  margin-bottom: 5% !important;}
.mb-10 {  margin-bottom: 10% !important;}
.mb-15 {  margin-bottom: 15% !important;}
.mb-20 {  margin-bottom: 20% !important;}
.mb-25 {  margin-bottom: 25% !important;}
.mb-30 {  margin-bottom: 30% !important;}

.mb-5-neg {  margin-bottom: -5% !important;}
.mb-10-neg {  margin-bottom: -10% !important;}
.mb-15-neg {  margin-bottom: -15% !important;}
.mb-20-neg {  margin-bottom: -20% !important;}
.mb-25-neg {  margin-bottom: -25% !important;}
.mb-30-neg {  margin-bottom: -30% !important;}

.ml-0 {  margin-left: 0 !important;}
.ml-2_5 {  margin-left: 2.5% !important;}
.ml-5 {  margin-left: 5% !important;}
.ml-10 {  margin-left: 10% !important;}
.ml-15 {  margin-left: 15% !important;}
.ml-20 {  margin-left: 20% !important;}
.ml-25 {  margin-left: 25% !important;}
.ml-30 {  margin-left: 30% !important;}

.mr-0 {  margin-right: 0 !important;}
.mr-2_5 {  margin-right: 2.5% !important;}
.mr-5 {  margin-right: 5% !important;}
.mr-10 {  margin-right: 10% !important;}
.mr-15 {  margin-right: 15% !important;}
.mr-20 {  margin-right: 20% !important;}
.mr-25 {  margin-right: 25% !important;}
.mr-30 {  margin-right: 30% !important;}

.mh-0   {  margin-left: 0 !important;margin-right: 0 !important;}
.mh-2_5 {  margin-left: 2.5% !important;margin-right: 2.5% !important;}
.mh-5   {  margin-left: 5% !important;margin-right: 5% !important;}
.mh-10  {  margin-left: 10% !important;margin-right: 10% !important;}
.mh-15  {  margin-left: 15% !important;margin-right: 15% !important;}
.mh-20  {  margin-left: 20% !important;margin-right: 20% !important;}
.mh-25  {  margin-left: 25% !important;margin-right: 25% !important;}
.mh-30  {  margin-left: 30% !important;margin-right: 30% !important;}

.ml-5-neg {  margin-left: -5% !important;}
.ml-10-neg {  margin-left: -10% !important;}
.ml-15-neg {  margin-left: -15% !important;}
.ml-20-neg {  margin-left: -20% !important;}
.ml-25-neg {  margin-left: -25% !important;}
.ml-30-neg {  margin-left: -30% !important;}

.my-2 { margin-top: 0.5rem !important;margin-bottom: 0.5rem !important; }
.my-4 { margin-top: 1.5rem !important;margin-bottom: 1.5rem !important;}


.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.font-56 {  font-size: 56pt !important;}
.font-50 {  font-size: 50pt !important;}
.font-46 {  font-size: 46pt !important;}
.font-40 {  font-size: 40pt !important;}
.font-36 {  font-size: 36pt !important;}
.font-30 {  font-size: 30pt !important;}
.font-24 {  font-size: 24pt !important;}
.font-22 {  font-size: 22pt !important;}
.font-20 {  font-size: 20pt !important;}
.font-18 {  font-size: 18pt !important;}
.font-16 {  font-size: 16pt !important;}
.font-15 {  font-size: 15pt !important;}
.font-14 {  font-size: 14pt !important;}
.font-13 {  font-size: 13pt !important;}
.font-12 {  font-size: 12pt !important;}
.font-11 {  font-size: 11pt !important;}
.font-10 {  font-size: 10pt !important;}
.font-8 {  font-size: 8pt !important;}

h3.font-10 {
  font-size: 10pt !important;
  line-height: 15pt !important;
}

h3.font-12 {
  font-size: 12pt !important;
  line-height: 18pt !important;
}

h3.font-18 {
  font-size: 18pt !important;
  line-height: 24pt !important;
}

h3.font-24 {
  font-size: 24pt !important;
  line-height: 32pt !important;
}

h2.font-14 {
  font-size: 14pt !important;
  line-height: 18pt !important;
}

h2.font-16 {
  font-size: 16pt !important;
  line-height: 18pt !important;
}

.w-2_5 {  width: 2.5% !important;}
.w-4 {  width: 4% !important;}
.w-5 {  width: 5% !important;}
.w-10 {  width: 10% !important;}
.w-15 {  width: 15% !important;}
.w-20 {  width: 20% !important;}
.w-25 {  width: 25% !important;}
.w-30 {  width: 30% !important;}
.w-35 {  width: 35% !important;}
.w-40 {  width: 40% !important;}
.w-50 {  width: 50% !important;}
.w-65 {  width: 65% !important;}
.w-75 {  width: 75% !important;}
.w-80 {  width: 80% !important;}
.w-85 {  width: 85% !important;}
.w-90 {  width: 90% !important;}
.w-95 {  width: 95% !important;}
.w-100 {  width: 100% !important;}

.w-min-5-vw {  min-width: 5vw !important;}
.w-min-10-vw {  min-width: 10vw !important;}
.w-min-15-vw {  min-width: 15vw !important;}
.w-min-20-vw {  min-width: 20vw !important;}
.w-min-25-vw {  min-width: 25vw !important;}
.w-min-30-vw {  min-width: 30vw !important;}
.w-min-35-vw {  min-width: 35vw !important;}
.w-min-40-vw {  min-width: 40vw !important;}
.w-min-50-vw {  min-width: 50vw !important;}
.w-min-75-vw {  min-width: 75vw !important;}
.w-min-80-vw {  min-width: 80vw !important;}
.w-min-85-vw {  min-width: 85vw !important;}
.w-min-90-vw {  min-width: 90vw !important;}
.w-min-95-vw {  min-width: 95vw !important;}
.w-min-100-vw {  min-width: 100vw !important;}

.w-max-5-vw {  max-width: 5vw !important;}
.w-max-10-vw {  max-width: 10vw !important;}
.w-max-15-vw {  max-width: 15vw !important;}
.w-max-20-vw {  max-width: 20vw !important;}
.w-max-25-vw {  max-width: 25vw !important;}
.w-max-30-vw {  max-width: 30vw !important;}
.w-max-35-vw {  max-width: 35vw !important;}
.w-max-40-vw {  max-width: 40vw !important;}
.w-max-50-vw {  max-width: 50vw !important;}
.w-max-75-vw {  max-width: 75vw !important;}
.w-max-80-vw {  max-width: 80vw !important;}
.w-max-85-vw {  max-width: 85vw !important;}
.w-max-90-vw {  max-width: 90vw !important;}
.w-max-95-vw {  max-width: 95vw !important;}
.w-max-100-vw {  max-width: 100vw !important;}

.w-min-5 {  min-width: 5% !important;}
.w-min-10 {  min-width: 10% !important;}
.w-min-15 {  min-width: 15% !important;}
.w-min-20 {  min-width: 20% !important;}
.w-min-25 {  min-width: 25% !important;}
.w-min-30 {  min-width: 30% !important;}
.w-min-35 {  min-width: 35% !important;}
.w-min-40 {  min-width: 40% !important;}
.w-min-50 {  min-width: 50% !important;}
.w-min-75 {  min-width: 75% !important;}
.w-min-80 {  min-width: 80% !important;}
.w-min-85 {  min-width: 85% !important;}
.w-min-90 {  min-width: 90% !important;}
.w-min-95 {  min-width: 95% !important;}
.w-min-100 {  min-width: 100% !important;}

.w-max-25 {  max-width: 25% !important;}
.w-max-40 {  max-width: 40% !important;}
.w-max-50 {  max-width: 50% !important;}
.w-max-75 {  max-width: 75% !important;}
.w-max-95 {  max-width: 95% !important;}
.w-max-100 {  max-width: 100% !important;}

.h-15 {  height: 15% !important;}
.h-20 {  height: 20% !important;}
.h-25 {  height: 25% !important;}
.h-50 {  height: 50% !important;}
.h-75 {  height: 75% !important;}
.h-100 {  height: 100% !important;}
.h-125 {  height: 125% !important;}
.h-150 {  height: 150% !important;}

.h-max-15 {  max-height: 15% !important;}
.h-max-20 {  max-height: 20% !important;}
.h-max-25 {  max-height: 25% !important;}
.h-max-50 {  max-height: 50% !important;}
.h-max-75 {  max-height: 75% !important;}
.h-max-100 {  max-height: 100% !important;}
.h-max-125 {  max-height: 125% !important;}
.h-max-150 {  max-height: 150% !important;}

.h-5-vw {  height: 5vw !important;}
.h-10-vw {  height: 10vw !important;}
.h-15-vw {  height: 15vw !important;}
.h-20-vw {  height: 20vw !important;}
.h-25-vw {  height: 25vw !important;}
.h-50-vw {  height: 50vw !important;}
.h-75-vw {  height: 75vw !important;}
.h-100-vw {  height: 100vw !important;}

.h-4-vh {  height: 4vh !important;}
.h-5-vh {  height: 5vh !important;}
.h-6-vh {  height: 6vh !important;}
.h-10-vh {  height: 10vh !important;}
.h-15-vh {  height: 15vh !important;}
.h-20-vh {  height: 20vh !important;}
.h-25-vh {  height: 25vh !important;}
.h-50-vh {  height: 50vh !important;}
.h-75-vh {  height: 75vh !important;}
.h-90-vh {  height: 90vh !important;}
.h-100-vh {  height: 100vh !important;}

.h-min-5-vh {  min-height: 5vh !important;}
.h-min-10-vh {  min-height: 10vh !important;}
.h-min-15-vh {  min-height: 15vh !important;}
.h-min-20-vh {  min-height: 20vh !important;}
.h-min-25-vh {  min-height: 25vh !important;}
.h-min-50-vh {  min-height: 50vh !important;}
.h-min-75-vh {  min-height: 75vh !important;}
.h-min-100-vh {  min-height: 100vh !important;}

.h-max-5-vh {  max-height: 5vh !important;}
.h-max-10-vh {  max-height: 10vh !important;}
.h-max-15-vh {  max-height: 15vh !important;}
.h-max-20-vh {  max-height: 20vh !important;}
.h-max-25-vh {  max-height: 25vh !important;}
.h-max-50-vh {  max-height: 50vh !important;}
.h-max-60-vh {  max-height: 60vh !important;}
.h-max-70-vh {  max-height: 70vh !important;}
.h-max-75-vh {  max-height: 75vh !important;}
.h-max-100-vh {  max-height: 100vh !important;}

.w-5-vw {  width: 5vw !important;}
.w-10-vw {  width: 10vw !important;}
.w-15-vw {  width: 15vw !important;}
.w-20-vw {  width: 20vw !important;}
.w-25-vw {  width: 25vw !important;}
.w-50-vw {  width: 50vw !important;}
.w-75-vw {  width: 75vw !important;}
.w-100-vw {  width: 100vw !important;}


.z-very-back {  z-index: -9999 !important;}
.z-back {  z-index: -999 !important;}
.z-front {  z-index: 999 !important;}
.z-very-front {  z-index: 9999 !important;}


.z-very-back-pos {
  z-index: -9999;
  position: relative;
}

.z-back-pos {
  z-index: -999;
  position: relative;
}

.z-front-pos {
  z-index: 999;
  position: relative;
}

.z-very-front-pos {
  z-index: 9999;
  position: relative;
}

.relative{  position: relative;}
.absolute {  position: absolute !important;}

.text-center {  text-align: center;}
.text-left {  text-align: left;}
.text-right {  text-align: right;}

.center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.vertical-center{
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.left {
  text-align: left;
  margin-right: auto;
  margin-left: 0;
}

.right {
  text-align: right;
  margin-left: auto;
}


.button-huge {
  padding: 3% !important;
}

.button-w-h {
  padding-bottom: 1%;
  padding-top: 1%;
  padding-left: 8%;
  padding-right: 8%;
}

.button-rounded {
  border-radius: 20px !important;
}

.p-1 {
  padding: 1% !important;
}

.p-2_5 {
  padding: 2.5% !important;
}

.p-5 {
  padding: 5% !important;
}

.p-10 {
  padding: 10% !important;
}

.p-15 {
  padding: 15% !important;
}

.p-20 {
  padding: 20% !important;
}

.p-25 {
  padding: 25% !important;
}

.p-35 {
  padding: 35% !important;
}

.p-50 {
  padding: 50% !important;
}

.ph-2_5 {
  padding-left: 2.5% !important;
  padding-right: 2.5% !important;
}

.ph-5 {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.ph-10 {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.ph-15 {
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.ph-20 {
  padding-left: 20% !important;
  padding-right: 20% !important;
}

.ph-25 {
  padding-left: 25% !important;
  padding-right: 25% !important;
}

.ph-30 {
  padding-left: 30% !important;
  padding-right: 30% !important;
}

.ph-35 {
  padding-left: 35% !important;
  padding-right: 35% !important;
}

.ph-50 {
  padding-left: 50% !important;
  padding-right: 50% !important;
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pv-1 {
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

.pv-2_5 {
  padding-top: 2.5% !important;
  padding-bottom: 2.5% !important;
}

.pv-5 {
  padding-top: 5% !important;
  padding-bottom: 5% !important;
}

.pv-10 {
  padding-top: 10% !important;
  padding-bottom: 10% !important;
}

.pv-15 {
  padding-top: 15% !important;
  padding-bottom: 15% !important;
}

.pv-20 {
  padding-top: 20% !important;
  padding-bottom: 20% !important;
}

.pv-25 {
  padding-top: 25% !important;
  padding-bottom: 25% !important;
}

.pt-2_5 {
  padding-top: 2.5% !important;
}

.pt-5 {
  padding-top: 5% !important;
}

.pt-10 {
  padding-top: 10% !important;
}

.pt-15 {
  padding-top: 15% !important;
}

.pt-20 {
  padding-top: 20% !important;
}

.pt-25 {
  padding-top: 25% !important;
}


.pb-2_5 {
  padding-bottom: 2.5% !important;
}

.pb-5 {
  padding-bottom: 5% !important;
}

.pb-10 {
  padding-bottom: 10% !important;
}

.pb-15 {
  padding-bottom: 15% !important;
}

.pb-20 {
  padding-bottom: 20% !important;
}

.pb-25 {
  padding-bottom: 25% !important;
}


.max-w-75vw {
  max-width: 75vw;
}

.no-wrap {
  white-space: nowrap;
  word-spacing: 0;
}

.pre-wrap {
  white-space: pre-wrap;
}

.normal-wrap {
  white-space: normal;
}


.btn-disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
  background-color: #ffffff;
}

.cursor-pointer{
  cursor: pointer;
}

.facebook-color{
  color: #0e8cf1;
}

.telegram-color{
  color: #0088cc;
}

.whatsapp-color{
  color: #00e676;
}

.mat-dialog-container {
  max-width: 100%;
  max-height: 100% !important;
  resize: both;
}

.mat-success {
  background-color: #116211;
  color: #fff;
}

.mat-white {
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
}

span.mat-button-wrapper {
  margin-right: 0
}

span.mat-button-wrapper {
  i {
    margin-left: 5px
  }
}

.font-normal {
  font-weight: 400 !important;
}

.font-light {
  font-weight: 300 !important;
}

.shadow {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12), 0 4px 5px rgba(0, 0, 0, 0.14), 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.bg-primary {
  color: white;
  background-color: $primary;
}

.bg-accent {
  color: white;

  background-color: $accent;
}

.bg-warn {
  color: white;
  background-color: $warn;
}

.overdue-toggle {
  border: $warn solid 1px!important;
  //border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  //border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  //border: $warn-light solid;
  //background-color: $warn-light;
}


.disclaimer-error {
  border-radius: 10px;
  color: $warn
}

.disclaimer-warning {
  border-radius: 10px;
  //color: rgba(255,202,54,0.85)
}

.border-radius-15 {
  border-radius: 15px;
}

.text-tag {
  font-size: 0.75rem;
  font-weight: bold;
}

// For order form
//.mat-option.customer {
//  height: auto;
//  line-height: unset;
//  padding: 1rem;
//  border-radius: 4px;
//  border: $accent solid 1px;
//  margin: 0.25rem
//}


// For search box
.mt-2   {margin-top: 0.5rem !important;}

.search-box {
  transition: flex-grow 0.15s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.38);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  max-width: 75rem;


  input {
    width: 0;
    //min-width: 0;
    //max-width: 0;
    flex-grow: 0;
    //display: flex;
    transition: width 0s ease-in-out;
    padding-right: 1.9rem !important;
    cursor: pointer;
  }

  input,
  input:focus {
    outline: none;
  }

  //&:hover input,
  &:focus-within input,
  input:not(:placeholder-shown),
  input:focus {
    width: 100%;
    cursor: initial;
    color: $primary;
  }

  &:focus-within {
    flex-grow: 1;
  }

}

.expanded-clickable-area {
  margin-bottom: auto;
  margin-left: 0.25rem;
}

.mat-tab-body-content {
  overflow: hidden!important;
}

.type-chip {
  border-color: #05542C !important;
  color: white;
  background-color: #05542C!important;
}
